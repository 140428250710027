import {
  ChainId,
  CHAIN_ID_ETH,
  CHAIN_ID_BSC,
  CHAIN_ID_POLYGON,
  CHAIN_ID_FANTOM,
  CHAIN_ID_CELO,
  CHAIN_ID_SOLANA,
  CHAIN_ID_BASE,
  CHAIN_ID_ARBITRUM,
  CHAIN_ID_OPTIMISM,
} from "@certusone/wormhole-sdk";

export function getExplorerLink(chainId: ChainId, address: string) {
  const explorerAddress =
    chainId === CHAIN_ID_ETH
      ? `https://etherscan.io/token/${address}`
      : chainId === CHAIN_ID_BSC
      ? `https://bscscan.com/token//${address}`
      : chainId === CHAIN_ID_POLYGON
      ? `https://polygonscan.com/token/${address}`
      : chainId === CHAIN_ID_FANTOM
      ? `https://ftmscan.com/token/${address}`
      : chainId === CHAIN_ID_CELO
      ? `https://explorer.celo.org/address/${address}`
      : chainId === CHAIN_ID_SOLANA
      ? `https://solscan.io/address/${address}`
      : chainId === CHAIN_ID_BASE
      ? `https://basescan.org/token/${address}`
      : chainId === CHAIN_ID_ARBITRUM
      ? `https://arbiscan.io/token/${address}`
      : chainId === CHAIN_ID_OPTIMISM
      ? `https://optimistic.etherscan.io/token/${address}`
      : undefined;

  return explorerAddress;
}
