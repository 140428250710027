import { useMemo, useState, useCallback } from "react";
import {
  Container,
  Box,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  CircularProgress,
} from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
// import { ChainId } from "@certusone/wormhole-sdk";
import { formatUnits } from "ethers/lib/utils";

import CustomTextField from "../components/CustomTextField";
import { shortenAddress } from "../utils/solana";
import { CHAINS_RECORD } from "../utils/consts";
import { useTokens, Token } from "../hooks/useTokens";
import { getExplorerLink } from "../utils/helper";
import CustomIcon from "./CustomIcon";

interface Data {
  serial: number;
  token: string;
  chain: string;
  supply: number;
  createdAt: string;
}
type Order = "asc" | "desc";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: "serial",
    numeric: true,
    label: "#",
  },
  {
    id: "token",
    numeric: false,
    label: "Token",
  },
  { id: "chain", numeric: false, label: "Chain" },
  // { id: "supply", numeric: true, label: "Supply" },
  {
    id: "createdAt",
    numeric: true,

    label: "Created",
  },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cont: {
      padding: "0",
      marginTop: "60px",
      marginBottom: "40px",
    },
    header: {
      fontWeight: 600,
      letterSpacing: 2,
      "@media (max-width:550px)": {
        textAlign: "center",
      },
    },
    headCont: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "5px 10px",
      "@media (max-width:550px)": {
        flexDirection: "column",
        alignItems: "start",
      },
    },
    field: {
      margin: "0",
      width: "40%",
      "@media (max-width:650px)": {
        width: "60%",
      },
      "@media (max-width:550px)": {
        width: "100%",
        margin: "5px 0",
      },
    },
    tableCont: {
      border: "1px solid",
      borderColor: theme.palette.divider,
      borderRadius: "12px",
      margin: "0 10px",
    },
    tableRow: {
      padding: "0 10px",
      borderRadius: "12px",
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    count: {
      display: "flex",
      alignItems: "center",
      padding: "15px 12px",
      "& h5": {
        fontWeight: 800,
      },
      "& h5:nth-of-type(2)": {
        color: theme.palette.text.secondary,
        marginLeft: "5px",
      },
    },
    tableHead: {
      background: theme.palette.secondary.main,
      borderRadius: "12px",
      overflow: "hidden",
      "& span:hover": {
        color: "inherit",
      },
      "& span": {
        fontSize: "14px",
        color: theme.palette.secondary.light,
        fontWeight: 600,
      },
      "& th:first-of-type": {
        borderRadius: "12px 0 0 0",
      },
      "& th:last-of-type": {
        borderRadius: "0 12px 0 0",
      },
    },
    token: {
      display: "flex",
      alignItems: "center",
      "& p:last-of-type": {
        marginLeft: "5px",
        color: theme.palette.text.secondary,
      },
      "@media (max-width:550px)": {
        flexDirection: "column",
        alignItems: "start",
        "& p:last-of-type": {
          marginLeft: 0,
        },
      },
    },
    address: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.primary.main,
      "& div": {
        marginRight: "5px",
      },
    },
    chain: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      "& img": {
        width: 20,
        height: 20,
        marginRight: "5px",
      },
    },
    loader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "200px",
      width: "100%",
      "& p": {
        color: theme.palette.text.secondary,
      },
    },
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      //   minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow className={classes.tableRow}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.id === "token" || headCell.id === "serial"
                ? "left"
                : "right"
            }
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function formatDate(timestamp: number) {
  const date = new Date(timestamp * 1000);
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
  return formattedDate;
}

const rowsPerPage = 5;

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof Data>("supply");
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>("");

  const { fetching, tokens } = useTokens();

  const formattedTokens = useMemo(
    () =>
      tokens
        ? tokens.reduce<Record<string, Token>>((accum, token) => {
            accum[token.address] = {
              ...token,
              supply: formatUnits(token.supply, token.decimals ?? 1),
            };
            return accum;
          }, {})
        : {},
    [tokens]
  );

  const rows = useMemo(
    () =>
      formattedTokens
        ? Object.values(formattedTokens).map((token, index) => ({
            serial: index + 1,
            token: token.address,
            chain: token.chain,
            supply: token.supply,
            createdAt: token.creation ?? 0,
          }))
        : [],
    [formattedTokens]
  );

  const sortedData = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy))
        .filter((item) => {
          const data = formattedTokens[item.token];
          return (
            data.address.toLowerCase().includes(search.toLowerCase()) ||
            (data.name ?? "").toLowerCase().includes(search.toLowerCase()) ||
            (data.symbol ?? "").toLowerCase().includes(search.toLowerCase())
          );
        })
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [rows, order, orderBy, search, page, formattedTokens]
  );

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: keyof Data) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [setOrder, setOrderBy]
  );

  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      setPage(newPage);
    },
    [setPage]
  );

  const handlseSearchChange = useCallback(
    (event) => {
      setSearch(event.target.value);
    },
    [setSearch]
  );

  return (
    <Container className={classes.cont} maxWidth="md">
      <Box className={classes.headCont}>
        <Typography className={classes.header} variant="h2">
          Tokens
        </Typography>
        <CustomTextField
          className={classes.field}
          placeholder="Search by name or address"
          value={search}
          onChange={handlseSearchChange}
          disabled={true}
        />
      </Box>
      <Box className={classes.tableCont}>
        {!fetching ? (
          rows.length > 0 ? (
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {sortedData.map((row) => {
                    const data = formattedTokens[row.token];
                    const chain = (CHAINS_RECORD as any)[data.chain];
                    return (
                      <TableRow
                        hover
                        className={classes.tableRow}
                        key={row.token}
                      >
                        <TableCell width={10} align="left">
                          {row.serial}
                        </TableCell>
                        <TableCell align="left">
                          <Box className={classes.token}>
                            <Typography variant="body1">{data.name}</Typography>
                            <Typography variant="body1">
                              ({data.symbol})
                            </Typography>
                          </Box>
                          <Typography
                            component="a"
                            href={getExplorerLink(chain?.id, data.address)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.address}
                          >
                            <Box>{shortenAddress(data.address)}</Box>
                            <LaunchIcon fontSize="small" />
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Box className={classes.chain}>
                            <CustomIcon src={chain.logo} alt={chain.name} />
                            <Typography variant="body1">
                              {chain.name}
                            </Typography>
                          </Box>
                        </TableCell>
                        {/* <TableCell align="right">
                          <Typography variant="body1">{row.supply}</Typography>
                        </TableCell> */}
                        <TableCell align="right">
                          {formatDate(row.createdAt)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box className={classes.loader}>
              <Typography>No tokens found</Typography>
            </Box>
          )
        ) : (
          <Box className={classes.loader}>
            <CircularProgress size={24} color="primary" />
          </Box>
        )}

        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Box>
    </Container>
  );
}
