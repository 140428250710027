import React, { useState, useEffect } from "react";
import { Button, makeStyles, Tooltip } from "@material-ui/core";
import { LinkOff } from "@material-ui/icons";
import { Connection, PublicKey } from "@solana/web3.js";
import { performReverseLookup } from "@bonfida/spl-name-service";
import { SOLANA_HOST } from "../utils/consts";

// Create Solana connection
const SOLANA_CONNECTION = new Connection(SOLANA_HOST);

const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex",
    width: "100%",
    borderRadius: "12px",
    height: "50px",
    fontSize: "18px",
    marginTop: "10px",
  },
  buttonConnected: {
    backgroundColor: theme.palette.secondary.main,
    border: "1px solid",
    borderColor: theme.palette.divider,
    borderRadius: "12px",
    width: "100%",
    marginTop: "10px",
    height: "40px",
    letterSpacing: "0.5px",
    "&:hover": {
      filter: "brightness(1.1)",
      backgroundColor: theme.palette.secondary.main,
    },
  },
  icon: {
    height: 24,
    width: 24,
  },
}));

const ToggleConnectedButton = ({
                                 connect,
                                 disconnect,
                                 connected,
                                 pk,
                                 walletIcon,
                                 disabled = false,
                               }: {
  connect(): any;
  disconnect(): any;
  connected: boolean;
  pk: string;
  walletIcon?: string;
  disabled?: boolean;
}) => {
  const classes = useStyles();
  const [solDomain, setSolDomain] = useState<string | null>(null);

  useEffect(() => {
    const fetchSolDomain = async () => {
      try {
        if (connected && pk) {
          const publicKey = new PublicKey(pk);
          const domain = await performReverseLookup(SOLANA_CONNECTION, publicKey);
          setSolDomain(domain);
        } else {
          setSolDomain(null);
        }
      } catch (error) {
        console.error("Error fetching .sol domain:", error);
        setSolDomain(null);
      }
    };

    fetchSolDomain();
  }, [connected, pk]);

  const is0x = pk.startsWith("0x");
  return (
    <div>
      {connected ? (
        <Tooltip title={pk}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={disconnect}
            className={classes.buttonConnected}
            startIcon={
              walletIcon ? (
                <img className={classes.icon} src={walletIcon} alt="Wallet" />
              ) : (
                <LinkOff />
              )
            }
          >
            {solDomain
              ? solDomain // Show the .sol domain if available
              : `-Disconnect ${pk.substring(0, is0x ? 6 : 3)}...${pk.substr(
                pk.length - (is0x ? 4 : 3)
              )}`}
          </Button>
        </Tooltip>
      ) : (
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={connect}
          className={classes.button}
          disabled={disabled}
        >
          Connect
        </Button>
      )}
    </div>
  );
};

export default ToggleConnectedButton;
