import { useState, useEffect, useCallback, useMemo } from "react";

import { getTokens } from "../utils/indexing";

export interface Token {
  address: string;
  chain: number;
  name?: string;
  symbol?: string;
  decimals?: number;
  supply: string;
  txnHash?: string;
  creation?: number;
}

export function useTokens() {
  const [fetching, setFetching] = useState<boolean>(false);
  const [tokens, setTokens] = useState<Array<Token>>();

  const fetchTokens = useCallback(async () => {
    setFetching(true);
    const data = await getTokens();
    setTokens(data);
    setFetching(false);
  }, [setTokens, setFetching]);

  useEffect(() => {
    fetchTokens();
  }, [fetchTokens]);

  return { fetching, tokens };
}
